const pages = {
  landings: [
    {
      title: 'Applicazione web',
      href: '/mobile-app',
    }
  ],
  company: [
    {
      title: 'Chi sono',
      href: '/about',
    }
  ],
  account: [
    {
      title: 'in costruzione ..',
      href: '/blog',
    }
  ],
  secondary: [
    {
      title: 'chiedi un preventivo',
      href: '/pricing',
    },
  ],

};

export default pages;
