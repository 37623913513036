import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const mock = [
  {
    image: 'https://raw.githubusercontent.com/giorgioGTelian/Portfolio_website/master/src/assets/img/portfolio/wordpress.jpg',
    description: "Il nostro progetto si focalizza sulla creazione di siti web dinamici e interattivi basati sulla piattaforma WordPress ",
    title: 'Blog e Siti Web',
    color: '#183167',
  },
  {
    image: 'https://raw.githubusercontent.com/giorgioGTelian/Portfolio_website/master/src/assets/img/portfolio/gestionale.jpg',
    description: 'Gestionale per amministrazione e gestione personalizzati per azienda qui sotto vedrete alcuni esempi di gestionali sviluppati per aziende clienti',
    title: 'Gestionali',
    color: '#183167',
  },
  {
    image: 'https://raw.githubusercontent.com/giorgioGTelian/Portfolio_website/master/src/assets/img/portfolio/mobile.jpg',
    description: 'Sviluppo di applicazioni mobile per Android e iOS, con interfaccia utente intuitiva e funzionalità avanzate',
    title: 'Applicazioni Mobile',
    color: '#ECB3BE',
  },
  {
    image: 'https://raw.githubusercontent.com/giorgioGTelian/Portfolio_website/master/src/assets/img/portfolio/blog.jpg',
    description: 'gestione di database per siti web e applicazioni, con funzionalità avanzate e sicurezza garantita',
    title: 'Gestione di database',
    color: '#ECB3BE',
  },
];

const Folio = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 2,
                '&:hover': {
                  '& img': {
                    transform: 'scale(1.2)',
                  },
                },
              }}
            >
              <Box
                component={'img'}
                loading="lazy"
                height={1}
                width={1}
                src={item.image}
                alt="..."
                minHeight={{ xs: 400, md: 600 }}
                sx={{
                  transition: 'transform .7s ease !important',
                  transform: 'scale(1.0)',
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                }}
              />
              <Box
                position={'absolute'}
                bottom={0}
                left={0}
                right={0}
                padding={3}
                sx={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 2%, ${item.color})`,
                }}
              >
                <Typography
                  variant={'h4'}
                  fontWeight={700}
                  sx={{ color: 'common.white' }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant={'h6'}
                  fontWeight={700}
                  sx={{ color: 'common.white' }}
                  gutterBottom
                >
                  {item.description}
                </Typography>
                <Button
                  size={'large'}
                  variant={'contained'}
                  color={'secondary'}
                >
                  Scopri di più
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Folio;
